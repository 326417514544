function introSize() {
  var topShift = $('#header').innerHeight();
  var heightChange = $(window).height() - topShift;
  // .slider-intro-wrap or .intro-wrap
  if($('.slider-intro-wrap').length) {
    $('.slider-intro-wrap').css({
      'top': topShift + 'px',
      'height': heightChange + 'px'
    });
  }
  if($('.intro-wrap').length) {
    $('.intro-wrap').css({
      'top': topShift + 'px',
      'height': heightChange + 'px'
    });
  }
  /*
  $('body').css({
    'padding-top': padHeight
  });*/
}

$(document).ready(function() {

	// generic year for copyright
    var d = new Date();
    $('.date-now').html(d.getFullYear());

    if($(window).width() > 768) {
    	$('.page-wrap').css({
    		'margin-top': $( window ).height() + 'px'
    	});
      $('#top').css({
        'margin-top': -$( window ).height() + 'px'
      });

      // permamnent black nav fixes
      introSize();
    }

    $(window).scroll(function(e) {
        //console.log($('body, html').scrollTop());
        var offset = $('body').scrollTop();
        var offsetIE = $('html').scrollTop();
        if ((offset <= 10) && (offsetIE <= 10)) {
            $('#header').removeClass('page-scrolled');
        }
        else {
            $('#header').addClass('page-scrolled');
        }
    });

    $('.happening').css({
      'min-height': ($(window).height() - 170) + 'px'
    });

    $(window).resize(function() {

    	if($(window).width() > 768) {
	    	targetHeight = $( window ).height();

	    	if(targetHeight > 400) {
	    		 $('.page-wrap').css({
			    	'margin-top': $( window ).height() + 'px'
			    });
          $('#top').css({
            'margin-top': -$( window ).height() + 'px'
          });

	    	}
        introSize();
	    }
      else {
        $('.page-wrap, #top').css({
            'margin-top':'0px'
          });
        if($('.slider-intro-wrap').length) {
          $('.slider-intro-wrap').css({
            'top': '0px',
            'height': '380px'
          });
        }
        if($('.intro-wrap').length) {
          $('.intro-wrap').css({
            'top': '0px',
            'height': '380px'
          });
        }
      }

      $('.happening').css({
        'min-height': ($(window).height() - 170) + 'px'
      });

    });

    if(!(/Android|iPhone|iPad|iPod|BlackBerry|Windows Phone/i).test(navigator.userAgent || navigator.vendor || window.opera)){
    	var s = skrollr.init({
    		forceHeight: false,
        smoothScrolling: false,
        mobileDeceleration: 0.004
    	});

      skrollr.menu.init(s, {
          animate: false,
          easing: 'outCubic',
          duration: function(currentTop, targetTop) {
              //return 1000;
              //console.log(currentTop + ', ' + targetTop);
              return Math.abs(currentTop - targetTop); // * 10;
          },
          updateUrl: false
      });

      document.addEventListener('orientationchange', function(e) {
        //alert('switch');
        window.scrollTo(0,0);
        $isotopecontainer.isotope('layout');
        $isotopecontainercase.isotope('layout');
        s.refresh();
      });

      $('.slider-intro-wrap').on('init', function(event, slick) {
        s.refresh();
      });

      $(window).resize(function() {
        s.refresh();
      });
    }


    $('.slider-intro-wrap').slick({
      dots: false,
      arrows: false,
      infinite: true,
      speed: 500,
      fade: true,
      autoplay: true,
      autoplaySpeed: 4000,
      pauseOnHover: false,
      touchMove: false,
      swipe: false,
      draggable: false,
      cssEase: 'linear'
    });


    $('.introfade1').css({'opacity':'0'}).delay(1000).animate({'opacity':'1'}, 500);
    $('.introfade2').css({'opacity':'0'}).delay(1100).animate({'opacity':'1'}, 500);

    $('.button-scroll-down').click(function(e) {
    	e.preventDefault();
    	//console.log('down');
    	//$('.page-wrap').offset().top
    	//if(s) {
    	//	s.animateTo(400);
    	//}
    	
    	//$('.page-wrap').animate({
        //       'margin-top': 0 + 'px'
        //}, 1000);
		
    });

    $('.btn-seymour').click(function(e) {
      e.preventDefault();

      $(this).toggleClass('active');

      var slideTarget = $(this).parent().parent().parent().children('.target-seymour');
      slideTarget.slideToggle();

      var slickTarget = slideTarget.find('.case-study-carousel');

      //console.log(slickTarget.hasClass('slick-initialized'));

      if(slickTarget.hasClass('slick-initialized')) {
        // unslick
        slickTarget.slick("unslick");
      }
      else {
        // slick
        slickTarget.slick({
          dots: false,
          prevArrow: '<button type="button" class="slick-prev"><span class="fa fa-chevron-left"></span></button>',
          nextArrow: '<button type="button" class="slick-next"><span class="fa fa-chevron-right"></span></button>',
        });
      }
    });

    $('.case-study-item-image .overlay').click(function() {
      var buttonTarget = $(this).parent().parent().parent().find('.btn-seymour');
      var slideTarget = $(this).parent().parent().parent().find('.target-seymour');
      var slickTarget = slideTarget.find('.case-study-carousel');

      slideTarget.slideToggle();
      buttonTarget.toggleClass('active');

      if(slickTarget.hasClass('slick-initialized')) {
        // unslick
        slickTarget.slick("unslick");
      }
      else {
        // slick
        slickTarget.slick({
          dots: false,
          prevArrow: '<button type="button" class="slick-prev"><span class="fa fa-chevron-left"></span></button>',
          nextArrow: '<button type="button" class="slick-next"><span class="fa fa-chevron-right"></span></button>',
        });
      }
    });




    // create / destroy on seymour show?

    //$('.case-study-carousel').slick({
    //  dots: false,
    //  prevArrow: '<button type="button" class="slick-prev"><span class="fa fa-chevron-left"></span></button>',
    //  nextArrow: '<button type="button" class="slick-next"><span class="fa fa-chevron-right"></span></button>',
    //});

  /*
    $('.case-study-carousel').each(function() {
      $(this).slick({
        dots: false,
        prevArrow: '<button type="button" class="slick-prev"><span class="fa fa-chevron-left"></span></button>',
        nextArrow: '<button type="button" class="slick-next"><span class="fa fa-chevron-right"></span></button>',
      });
    });
*/


    $('.product-range > .btn-more').click(function(e){
      e.preventDefault();
      //$(this).css("height", $(this).height());
      //$(this).slideUp();
      $(this).toggleClass('active');
      $(this).next().slideToggle();
    });

    $('#tabs a').click(function (e) {

      if(!$(this).hasClass('btn-side-alt')) {

        e.preventDefault();
        tabtarget = $(this).data("toggle");
        if($('#' + tabtarget).is(":visible")) {
          //console.log('active');
        }
        else {
          $('#tabs a').each(function() { $(this).removeClass("active");});

          $(this).addClass("active");

          $('.tab-content .tab-pane.active').animate({
            'opacity': '0'
          }, 200, function() {
            $(this).hide().removeClass('active');
          });
          setTimeout(function() {
            $('#' + tabtarget).css({ 'opacity': '0', 'display' : 'block'}).addClass('active').animate({
            'opacity': '1'
          });
          },300);
          
          //console.log('not active');
        }
        //console.log($(this).data("toggle"));

      }
  		
	});

  $('#about, #content, #examples, #projects, #products, #sectors, section[id^="productsitem"], section[id^="happening"]').each(function() {
    // ignore first products item
    if((this.id == "products") && ($('section[id^="productsitem"]').length > 0 )) {
      // skip insertion in this case
    }
    else {
      $('#sidenav-trailers').before('<li><a href="#' + this.id + '"><span class="fa fa-circle-o"></span></a></li>');
    } 
  });


   

});

$(window).load(function() {

  if($('.btn-seymour').length > 0) {

    var buttonTarget = $('.btn-seymour:first');
    

    buttonTarget.toggleClass('active');

    var slideTarget = buttonTarget.parent().parent().parent().children('.target-seymour');
    slideTarget.slideToggle();

    var slickTarget = slideTarget.find('.case-study-carousel');

      //console.log(slickTarget.hasClass('slick-initialized'));

    if(slickTarget.hasClass('slick-initialized')) {
      // unslick
      slickTarget.slick("unslick");
    }
    else {
      // slick
        slickTarget.slick({
            lazyLoad: 'ondemand',
            dots: false,
            prevArrow: '<button type="button" class="slick-prev"><span class="fa fa-chevron-left"></span></button>',
            nextArrow: '<button type="button" class="slick-next"><span class="fa fa-chevron-right"></span></button>',
      });
    }
  }

  if($('.imageonly-gallery').length > 0) {
    var galleryonly = $('.imageonly-gallery').next().find('.case-study-carousel');
    $('.imageonly-gallery').next().css({
      'display':'block'
    });
    galleryonly.slick({
        dots: false,
        prevArrow: '<button type="button" class="slick-prev"><span class="fa fa-chevron-left"></span></button>',
        nextArrow: '<button type="button" class="slick-next"><span class="fa fa-chevron-right"></span></button>',
      });
  }

  if($('#latest #tabs a').length > 0) {
    $('#latest #tabs a:first').addClass('active');
    $('#latest .tab-pane:first').addClass('active');
  }

  if($(window).width() > 768) {
    introSize();
  }

});
